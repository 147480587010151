// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useQuery } from 'react-apollo';

import { GET_VALIDATED_USE_CASE_RESULTS_BY_USER } from '@/components/queries/cop/use_case_results';
import { loadObservationMetadata, cleanMetadata } from '@/actions/observationActions';

/**
 * This component loads the metada from the backend and parse it to
 * a format that the store expects. It's implemented as component
 * to not break the declarative aproach that react-apollo provides,
 * otherwise the query would be called imperatively.
 *
 */
const UseCaseResultsMetadataSetter = ({ dispatch, user, useCaseId, validatedFilter }) => {
  const { loading, data, error, refetch } = useQuery(GET_VALIDATED_USE_CASE_RESULTS_BY_USER, {
    variables: { userId: user.id, useCaseId, validatedFilter }
  });

  useEffect(() => {
    refetch();
  }, [useCaseId]);

  useEffect(() => {
    dispatch(cleanMetadata());
    if (data?.useCaseResults?.nodes && data?.useCaseResults?.nodes.length > 0) {
      [...data.useCaseResults.nodes].forEach(useCaseResult => {
        if (useCaseResult.validatedForDeletion || useCaseResult.validatedType) {
          dispatch(
            loadObservationMetadata({
              [useCaseResult.id]: {
                action: useCaseResult.validatedForDeletion
                  ? 'delete'
                  : useCaseResult.validatedType.toLowerCase(),
                relations: new Set(useCaseResult.attachments)
              }
            })
          );
        }
      });
    }
  }, [data, loading, error]);

  return null;
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(UseCaseResultsMetadataSetter);
