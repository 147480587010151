import React from 'react';
import Menu from './components/Menu';
import { useSelector } from 'react-redux';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import MobileCameras from './containers/MobileCameras';
import FixedCamerasDashboard from './containers/FixedCameras/components/FixedCamerasDashboard';
import Exemptions from './containers/Exemptions';
import UseCases from './containers/UseCases';

import 'styles/scenes/cop_settings.scss';
import CameraStatus from './containers/CameraStatus';

const Settings = () => {
  const match = useRouteMatch();
  const copStatusReadPermission = useSelector(state => state.user?.cop_status_read_permission);

  return (
    <div className="cop_settings">
      <Menu />

      <Switch>
        <Route path={`${match.path}/use_cases`}>
          <UseCases />
        </Route>

        <Route path={`${match.path}/fixed_cameras`}>
          <FixedCamerasDashboard />
        </Route>

        <Route path={`${match.path}/mobile_cameras`}>
          <MobileCameras />
        </Route>

        <Route path={`${match.path}/exemptions`}>
          <Exemptions />
        </Route>

        {copStatusReadPermission && (
          <Route path={`${match.path}/camera_status`}>
            <CameraStatus />
          </Route>
        )}

        {/* Default route is reserved for Use Cases. Needs to be last for URL matching */}
        <Route path={`${match.path}`}>
          {/* TODO: Add use cases component */}
          <p>use_cases</p>
        </Route>
      </Switch>
    </div>
  );
};

export default Settings;
