import React from 'react';

import Card from '../components/Card';
import Tabs from '../components/Tabs';
import Remarks from './Remarks';
import Attachments from './Attachments';
import AuditLogs from './AuditLogs';

const MiscCard = ({ formik }) => {
  const { attachments, id } = formik.values;

  return (
    <Card
      title={I18n.t('tickets.full_ticket.titles.miscellaneous')}
      colorScheme={{ primaryColor: '#494949', secondaryColor: '#F6F4F4' }}
    >
      <Tabs>
        <div label={I18n.t('tickets.full_ticket.titles.attachments')}>
          <Attachments formik={formik} attachments={attachments} />
        </div>
        <div label={I18n.t('tickets.full_ticket.titles.comments')} disabled={!id}>
          <Remarks formik={formik} ticketId={id} />
        </div>
        <div label={I18n.t('tickets.full_ticket.titles.auditlog')} disabled={!id}>
          <AuditLogs ticketId={id} />
        </div>
      </Tabs>
    </Card>
  );
};

export default MiscCard;
