import React from 'react';

import { Tab, Tabs } from 'brickyard-ui';

import LeftMenu from './LeftMenu';
import AreaOccupancyDashboard from '@/components/AreaOccupancy/AreaOccupancyDashboard';
import ScheduledOverrideTable from '@/components/AreaOccupancy/ScheduledOverrideTable';

import '@/styles/scenes/area_occupancy.scss';

const AreaOccupancy = ({ countingAreas }) => {
  return (
    <div className="app-container">
      <LeftMenu />
      <div id="area-occupancy">
        <Tabs defaultActiveKey="dashboard" mountOnEnter unmountOnExit variant="tabs">
          <Tab eventKey="dashboard" title={I18n.t('counting_reporters.dashboard.title')}>
            <AreaOccupancyDashboard areas={countingAreas} />
          </Tab>

          <Tab
            eventKey="scheduled"
            mountOnEnter
            unmountOnExit
            title={I18n.t('scheduled_override.title')}
          >
            <ScheduledOverrideTable areas={countingAreas} />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default AreaOccupancy;
