import React, { useEffect, useImperativeHandle } from 'react';
import { Alert } from 'brickyard-ui';
import { useDispatch, useSelector } from 'react-redux';
import { selectUseCasesState, useUseCasesSlice } from '../../slice';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FormFieldSmall from '../../../../components/FormFieldSmall';
import LabelToggle from '../../../../../../Ticket/Cards/components/LabelToggle';
import FormFieldBlock from '../../../../components/FormFieldBlock';

function ExemptionChecker({ outerRef, persistPipelineProcessor, editEnabled }) {
  const { selectedProcessor } = useSelector(selectUseCasesState);
  const { actions } = useUseCasesSlice();
  const formik = useFormik({
    initialValues: {
      id: selectedProcessor?.id,
      name: selectedProcessor?.name,
      exempt_on_error: !!selectedProcessor?.config?.exempt_on_error
    },
    enableReinitialize: true,
    onSubmit: async values => {
      await persistPipelineProcessor({
        variables: {
          id: values.id,
          name: values.name,
          config: { exempt_on_error: values.exempt_on_error }
        }
      });
    },
    validationSchema: Yup.object({
      name: Yup.string().required(
        I18n.t('settings.pipelines.processors.form.validations.name_required')
      )
    })
  });

  useImperativeHandle(outerRef, () => {
    return {
      handleSave: () => formik.submitForm(),
      handleCancel: () => formik.resetForm(),
      handleChange: formik.handleChange
    };
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.setErrors(formik.errors));
  }, [formik.errors]);

  return (
    <>
      <FormFieldBlock
        field={
          <>
            <FormFieldSmall
              field={
                <LabelToggle
                  id="exempt_on_error"
                  name="exempt_on_error"
                  isOn={formik.values.exempt_on_error}
                  onChange={formik.handleChange}
                  disabled={!editEnabled}
                />
              }
              error={formik.errors.exempt_on_error}
              label={
                formik.values.exempt_on_error ? (
                  <div className="alert alert-success alert-switch" role="alert">
                    {I18n.t('settings.pipelines.processors.messages.on_error_exempt')}
                  </div>
                ) : (
                  <div className="alert alert-primary alert-switch" role="alert">
                    {I18n.t('settings.pipelines.processors.messages.on_error_continue')}
                  </div>
                )
              }
            />
          </>
        }
      />

      <Alert variant="warning">
        {I18n.t('settings.pipelines.processors.form.exemption_checker.no_properties')}
      </Alert>
    </>
  );
}

export default ExemptionChecker;
