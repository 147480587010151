import React, { useEffect } from 'react';
import formatDate from '@/utils/formatDate';
import { useQuery } from 'react-apollo';
import { Modal, Col, Row, Container, Loading } from 'brickyard-ui';
import { LongDateFormat } from '@/utils/DateFormats';

import { GET_USE_CASE_RESULT_LOGS } from '../queries/cop/use_case_results';
import { useSelector } from 'react-redux';

const TabbedPanelModal = ({ useCaseId, useCaseResultId, showModal, setShowModal }) => {
  let rowIndex = 0;
  const filters = useSelector(state => state.observations.filters);
  const { loading, data, error, refetch, startPolling, stopPolling } = useQuery(
    GET_USE_CASE_RESULT_LOGS,
    {
      variables: {
        id: useCaseResultId,
        useCaseId: useCaseId,
        validatedFilter: filters.validatedFilter
      }
    }
  );

  useEffect(() => {
    if (showModal) {
      refetch().then(() => {
        startPolling(3000);
      });
    } else {
      stopPolling();
    }
  }, [showModal]);

  if (error || !data) return null;

  const responses = data.useCaseResults.nodes[0]?.logs || [];

  const renderField = row => {
    rowIndex++;
    return (
      <Row key={`ucr_response${rowIndex}`} className="row-content pt-2 pb-2">
        <Col xs="2">{row.timestamp ? formatDate(row.timestamp, LongDateFormat) : ' - '}</Col>
        <Col xs="2">{row.pipeline_name}</Col>
        <Col xs="2">{row.processor_name}</Col>
        <Col xs="6">{row.entry}</Col>
      </Row>
    );
  };

  return (
    <>
      {loading && <Loading />}
      <Modal
        size="xl"
        centered
        show={showModal}
        onHide={() => setShowModal(false)}
        className="ucr-pipeline-results-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{I18n.t('use_case.result.logs.modal_title')}</Modal.Title>
        </Modal.Header>

        <Modal.Body className="ucr-pipeline-results-body pt-0">
          <Container>
            <Row className="font-weight-bold sticky-top row-header pt-2 pb-2">
              <Col xs="2">{I18n.t('use_case.result.logs.timestamp')}</Col>
              <Col xs="2">{I18n.t('use_case.result.logs.pipeline_name')}</Col>
              <Col xs="2">{I18n.t('use_case.result.logs.processor_name')}</Col>
              <Col xs="6">{I18n.t('use_case.result.logs.entry')}</Col>
            </Row>
            {responses.map(response => renderField(response))}
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TabbedPanelModal;
