import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Card, Form as BYForm, Button, Spinner } from 'brickyard-ui';
import TestBoundary from '@/utils/TestBoundary';
import LoggedOutModal from '../shared/ActivityChecker/LoggedOutModal';
import { SHOW_LOGGED_OUT_MODAL } from '../../utils/consts';

import '../../styles/scenes/login.scss';

import Api from '../../utils/Api';

class Login extends React.Component {
  constructor(props) {
    super(props);
    I18n.locale = props.locale;
    this.thirdParties = props.thirdParties;
    this.forgotPasswordEnabled = props.forgotPasswordEnabled;

    this.state = {
      loading: false,
      apiErr: null,
      showLoggedOutModal: !!localStorage.getItem(SHOW_LOGGED_OUT_MODAL)
    };

    this._handleChange = this._handleChange.bind(this);
    this.doLogin = this.doLogin.bind(this);
    this.loginSchema = Yup.object().shape({
      user: Yup.object().shape({
        login: Yup.string().required(I18n.t('forms.validation.required')),
        password: Yup.string().required(I18n.t('forms.validation.required'))
      })
    });
  }

  _handleChange(key, val) {
    this.setState({ [key]: val });
  }

  hideLoggedOutModal() {
    localStorage.removeItem(SHOW_LOGGED_OUT_MODAL);
    this.setState({ showLoggedOutModal: false });
  }

  // Use this when we definitely turn to the new UI, otherwise it will prevent
  // the flash container to appear
  async doLogin(values) {
    this.setState({ loading: true, apiErr: null });
    try {
      await Api.post('/login?ui=new&ui_tickets=new', values);
      window.location = '/';
    } catch (_e) {
      this.setState({ apiErr: _e.response.data.error }, () =>
        setTimeout(() => {
          this.setState({ apiErr: '' });
        }, 5000)
      );
      this.setState({ loading: false });
    }
  }

  render() {
    return (
      <>
        <TestBoundary>
          <LoggedOutModal
            show={this.state.showLoggedOutModal}
            onHide={this.hideLoggedOutModal.bind(this)}
          />
        </TestBoundary>

        {!!this.state.apiErr && (
          <div
            className="flash-container"
            style={{
              position: 'fixed',
              left: '50%',
              top: '40px',
              transform: 'translate(-50%, 0)',
              width: '100%',
              textAlign: 'center',
              zIndex: 100
            }}
          >
            <div
              className="flash flash_alert"
              style={{ width: 'fit-content', minWidth: '500px', margin: 'auto' }}
            >
              {this.state.apiErr}
            </div>
          </div>
        )}
        <br />
        <Card className="login-card">
          <Card.Header>{I18n.t('devise.sessions.new.title.sign_in')}</Card.Header>
          <Formik
            initialValues={{
              user: {
                login: '',
                password: ''
              }
            }}
            validationSchema={this.loginSchema}
            onSubmit={this.doLogin}
          >
            {({ errors, touched, handleSubmit }) => (
              <form className="login-form" onSubmit={handleSubmit}>
                <input
                  type="hidden"
                  name="authenticity_token"
                  value={document.querySelector('[name=csrf-token]').content}
                />
                <Field
                  name="user[login]"
                  render={({ field }) => (
                    <BYForm.Group>
                      <BYForm.Control
                        {...field}
                        placeholder={`${I18n.t('activerecord.attributes.user.email')}/${I18n.t(
                          'activerecord.attributes.user.username'
                        )}`}
                        isValid={
                          touched.user && touched.user.login && (!errors.user || !errors.user.login)
                        }
                        id="login"
                      />
                      <ErrorMessage
                        name="user.login"
                        render={msg => (
                          <BYForm.Control.Feedback type="invalid">{msg}</BYForm.Control.Feedback>
                        )}
                      />
                    </BYForm.Group>
                  )}
                />

                <Field
                  name="user[password]"
                  render={({ field }) => (
                    <BYForm.Group>
                      <BYForm.Control
                        {...field}
                        placeholder={I18n.t('activerecord.attributes.user.password')}
                        type="password"
                        id="password"
                        isValid={
                          touched.user &&
                          touched.user.password &&
                          (!errors.user || !errors.user.password)
                        }
                      />
                      <ErrorMessage
                        name="user.password"
                        render={msg => (
                          <BYForm.Control.Feedback type="invalid">{msg}</BYForm.Control.Feedback>
                        )}
                      />
                    </BYForm.Group>
                  )}
                />

                <Button
                  disabled={this.state.loading}
                  variant="by-primary"
                  type="submit"
                  block
                  id="login_btn"
                >
                  {!this.state.loading && I18n.t('devise.sessions.new.sign_in')}
                  {this.state.loading && <Spinner animation="border" />}
                </Button>

                {!!this.props.message && (
                  <small>
                    <br />
                    <div dangerouslySetInnerHTML={{ __html: this.props.message }}></div>
                  </small>
                )}
              </form>
            )}
          </Formik>

          {this.forgotPasswordEnabled && (
            <Card.Footer>
              <a href="/password/new">{I18n.t('forms.login.forgot_password')}</a>
            </Card.Footer>
          )}
        </Card>

        <br />
        <br />

        <div className="text-center">
          {this.thirdParties.map(provider => (
            <a href={provider[1]} data-method="post" key={provider}>
              <img src={provider[2]} alt={provider[0]} />
            </a>
          ))}
        </div>
      </>
    );
  }
}

export default Login;
