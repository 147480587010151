import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { useSelector, useDispatch } from 'react-redux';
import { forEach, isEmpty } from 'ramda';

import { Loading, Alert } from 'brickyard-ui';
import UseCaseResultTabs from './UseCaseResultTabs';
import DeleteReasonModal from '../shared/observations/DeleteReasonModal';

import { GET_DETAILED_USE_CASE_RESULT } from '@/components/queries/cop/use_case_results';
import { useQuery, useMutation } from 'react-apollo';

import {
  addObservationActionMetadata,
  addObservationRelationMetadata,
  removeObservationRelationMetadata,
  removeObservationMetadata
} from '../../actions/observationActions';

import TestBoundary from '@/utils/TestBoundary';

import 'styles/shared/use_cases.scss';

export const VALIDATE_ATTACH = gql`
  mutation ValidateAttach(
    $id: ID!
    $type: CopUseCaseResultValidationTypes!
    $attachments: [ID!]
    $reasonId: ID
    $exemptionTypeId: ID
    $comment: String
  ) {
    copValidateAndAttach(
      id: $id
      validatedType: $type
      attachmentIds: $attachments
      reasonId: $reasonId
      exemptionTypeId: $exemptionTypeId
      comment: $comment
    ) {
      id
      validatedType
      attachments {
        id
      }
    }
  }
`;

/**
 * For starting and stopping polling on this component, you need to do the oposite on
 * the table's parent component, otherwise it will conflict the data syncing.
 */
const UseCaseResultContainer = ({
  useCase,
  onReset,
  onStartPolling,
  onStopPolling,
  type,
  isLastObservation,
  shiftPagination,
  hasNextPage,
  validatedFilter
}) => {
  const observationId = useSelector(state => state.observations.current);
  const metadata = useSelector(state => state.observations);
  const dispatch = useDispatch();
  const [mainObservationId, setMainObservationId] = useState(observationId);
  const [showDismissalModal, setShowDismissalModal] = useState(false);
  /**
   * For selecting the dismissal reason we have two different "triggers":
   * - Delete button regarding the mainObsevation
   * - A related observation that will be deleted
   *
   * As the dismissal reason is set on a modal, for reusing the same flow
   * the dismissalObs is set to set the metadata after hiding the deleting
   * reason modal.
   *
   **/
  const [dismissalObservations, setDismissalObservations] = useState([]);

  const { loading, data, error, refetch, startPolling, stopPolling } = useQuery(
    GET_DETAILED_USE_CASE_RESULT,
    {
      variables: { id: observationId, useCaseId: useCase.id, validatedFilter }
    }
  );

  const [validateAttach] = useMutation(VALIDATE_ATTACH);

  const getObsStatus = obs => {
    if (metadata[obs.id]) {
      return metadata[obs.id].action || 'unrelated';
    }
    if (metadata[mainObservationId] && metadata[mainObservationId].relations) {
      return metadata[mainObservationId].relations.has(obs.id) ? 'related' : 'unrelated';
    }

    return 'unrelated';
  };

  useEffect(() => {
    setMainObservationId(observationId);
  }, [observationId]);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (data.useCaseResults.nodes.length) {
      startPolling(3000);
      onStartPolling();
    } else {
      stopPolling();
      onStopPolling(3000);
    }
  }, [data]);

  useEffect(() => {
    return () => {
      stopPolling();
      onStopPolling(3000);
    };
  }, []);

  const switchMain = async obs => {
    setMainObservationId(obs.id);
    await refetch({ id: obs.id, useCaseId: useCase.id });
    dispatch(removeObservationMetadata(mainObservationId));
  };

  const addToDelete = (obs, reasonId = null) => {
    if (useCase.dismissalReasonRequired && !reasonId) {
      return;
    }
    if (getObsStatus(obs) === 'related') {
      unrelate(obs);
    }
    validateAttach({
      variables: {
        id: obs.id,
        type: 'DELETE',
        reasonId
      }
    });
    dispatch(
      addObservationActionMetadata({
        observationId: obs.id,
        action: 'delete',
        dismissalReason: reasonId
      })
    );
  };

  const relate = obs => {
    if (obs.id !== mainObservationId && !!metadata[obs.id]) {
      dispatch(removeObservationMetadata(obs.id));
    }
    dispatch(addObservationRelationMetadata({ mainObservationId, relatedId: obs.id }));
  };

  const unrelate = obs => {
    dispatch(removeObservationRelationMetadata({ mainObservationId, relatedId: obs.id }));
  };

  const markTo = (action, validateAttach) => {
    dispatch(
      addObservationActionMetadata({
        observationId: mainObservationId,
        action
      })
    );
    if (action === 'ticket' || action === 'warning') {
      validateAttach({
        variables: {
          id: mainObservationId,
          type: action.toUpperCase(),
          attachments: Array.from(
            metadata[mainObservationId] ? metadata[mainObservationId].relations || [] : []
          )
        }
      });
    } else {
      if (action?.type === 'exemption') {
        validateAttach({
          variables: {
            id: mainObservationId,
            type: action?.type.toUpperCase(),
            attachments: Array.from(
              metadata[mainObservationId] ? metadata[mainObservationId].relations || [] : []
            ),
            exemptionTypeId: parseInt(action?.exemptionTypeId),
            comment: action?.comment
          }
        });
      }
    }
    if (isLastObservation && hasNextPage) {
      shiftPagination();
    }
  };

  const changeRelation = (status, target) => {
    const statusController = {
      delete: observations => {
        if (useCase.dismissalReasonRequired) {
          setDismissalObservations(observations);
          setShowDismissalModal(true);
        } else {
          observations.forEach(observation => {
            addToDelete(observation);
          });
        }
      },
      main: forEach(switchMain),
      relate: forEach(relate),
      unrelate: forEach(unrelate)
    };
    statusController[status](target);
  };

  const processDeleteWithReason = reasonId => {
    setShowDismissalModal(false);

    if (!reasonId || isEmpty(dismissalObservations)) {
      return;
    }

    dismissalObservations.forEach(observation => {
      addToDelete(observation, reasonId);
    });

    setDismissalObservations([]);
  };

  if (loading) return <Loading />;
  if (error) return <p>{error.toString()}</p>;

  return (
    <>
      <div className="ucr-details">
        <TestBoundary>
          {data.useCaseResults.nodes[0] ? (
            <UseCaseResultTabs
              key={data.useCaseResults.nodes[0].id}
              mainObservation={data.useCaseResults.nodes[0]}
              getObsStatus={getObsStatus}
              markTo={action => markTo(action, validateAttach)}
              onRelationChange={changeRelation}
              onReset={onReset}
              useCase={useCase}
              type={type}
            />
          ) : (
            <Loading />
          )}
        </TestBoundary>
        {showDismissalModal && (
          <TestBoundary>
            <DeleteReasonModal
              show={showDismissalModal}
              onHide={() => setShowDismissalModal(false)}
              onSelect={processDeleteWithReason}
            />
          </TestBoundary>
        )}
      </div>
      {data.useCaseResults.nodes[0] && data.useCaseResults.nodes[0].pipelineFailed && (
        <Alert variant="danger" className="pipeline-failed">
          {I18n.t('use_case.errors.messages.pipeline_failed')}
        </Alert>
      )}
      {data.useCaseResults.nodes[0] && data.useCaseResults.nodes[0].showSuspect && (
        <Alert variant="warning" className="pipeline-failed">
          {I18n.t('use_case.errors.messages.show_suspect')}
        </Alert>
      )}
    </>
  );
};

export default UseCaseResultContainer;
