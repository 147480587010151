import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Close from '@/assets/img/close_icon.svg';
import { selectFixedCamerasState, useFixedCamerasSlice } from '../slice';
import { Loading, Alert } from 'brickyard-ui';
import { useFormik } from 'formik';
import FormField from '../../../components/FormField';
import LabelToggle from '../../../../../Ticket/Cards/components/LabelToggle';
import OutsideClickHandler from '../../../../../../OutsideClickHandler';
import fixedCameraSchema from '../validationSchema';
import CameraFormMap from './CameraFormMap';
import InputConfirmationModal from '../../../components/InputConfirmationModal';
import FormControls from '../../../components/FormControls';
import { COP_PERM_WRITE, ANPR_XML, JSON_PROTOCOLS } from '../../../../../../../utils/consts';
import CameraFormOverviewImage from './CameraFormOverviewImage';

const CameraFormPanel = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  .loading-box {
    margin: auto;
  }

  .click-handler-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .form-header {
      display: flex;
      width: (100% - 80px);
      margin: 10px;
      height: 50px;

      h5 {
        display: flex;
        color: #4a4a49;
        font-weight: 600;
        margin: auto auto auto 10px;

        .static-title {
          font-weight: 300;
          margin-right: 7px;
        }
      }

      .close-button {
        margin: auto 10px auto auto;
        cursor: pointer;
      }
    }

    .form-body {
      display: flex;
      flex-direction: row;
      width: (100% - 80px);
      height: calc(100% - 120px);
      margin: 10px;
      margin-top: 0px;

      .left-side {
        display: flex;
        width: 50%;
        flex-direction: column;
        overflow-y: auto;
      }

      .right-side {
        display: flex;
        width: 50%;
        flex-direction: column;
        overflow-y: auto;
        height: 100%;
      }
    }
  }
`;

const CameraForm = ({ camera, onClose, isLoading, backendValidationError }) => {
  const state = useSelector(selectFixedCamerasState);
  const { actions } = useFixedCamerasSlice();
  const dispatch = useDispatch();
  const params = useParams();

  const user = useSelector(state => state.user);
  const hasWritePermission = user?.cop_settings_permission === COP_PERM_WRITE;

  const [editEnabled, setEditEnabled] = useState(false);
  const editEnabledRef = useRef();
  editEnabledRef.current = editEnabled;

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [outsideClickEnabled, setOutsideClickEnabled] = useState(false);
  useEffect(() => {
    setOutsideClickEnabled(!showDeleteConfirmation);
  }, [showDeleteConfirmation]);

  const formik = useFormik({
    initialValues: camera ?? {
      city: '',
      feeds_whitelist: false,
      ip_addr: '',
      latitude: '',
      location: '',
      longitude: '',
      mac_address: '',
      municipality: '',
      name: '',
      overview_image_url: null,
      port: '',
      protocol: 'anpr_xml',
      railway_moped_on_main_road: false,
      road_type_id: '',
      rotation: '',
      status: 'disabled',
      street: '',
      tiny_system_states_message_active: false,
      within_city_limits: false,
      uses_legacy_daemon: true
    },
    enableReinitialize: true,
    onSubmit: values => {
      const propsToRemove = ['id', 'updated_at', 'created_at'];

      const filteredValues = Object.keys(values)
        .filter(key => !propsToRemove.includes(key))
        .reduce((obj, key) => {
          return { ...obj, [key]: values[key] };
        }, {});

      if (params.id === 'new') {
        dispatch(
          actions.createCamera({
            type: 'Cop::FixedCamera',
            props: filteredValues,
            onSuccess: () => {
              setEditEnabled(false);
              onClose();
            }
          })
        );
      } else {
        dispatch(
          actions.updateCamera({
            id: parseInt(params.id),
            props: filteredValues,
            onSuccess: () => {
              setEditEnabled(false);
              onClose();
            }
          })
        );
      }
    },
    validationSchema: fixedCameraSchema
  });

  const removeOverviewImage = async () => {
    dispatch(
      actions.removeOverviewImage({
        id: parseInt(params.id),
        type: 'Cop::FixedCamera'
      })
    );
  };

  const protocolOptions = [
    { value: 'anpr_xml', label: I18n.t('settings.protocol_anpr_xml_label') },
    { value: 'anpr_json', label: I18n.t('settings.protocol_anpr_json_label') },
    { value: 'railway_json', label: I18n.t('settings.protocol_railway_json_label') },
    { value: 'bop', label: I18n.t('settings.protocol_bop_label') }
  ];

  const statusOptions = [
    { value: 'disabled', label: I18n.t('settings.status_disabled_label') },
    { value: 'enabled', label: I18n.t('settings.status_enabled_label') }
  ];

  const railwaySpeedLimitOptions = [
    { value: '', label: '' },
    { value: '30', label: '30' },
    { value: '50', label: '50' },
    { value: '60', label: '60' },
    { value: '70', label: '70' },
    { value: '80', label: '80' }
  ];

  useEffect(() => {
    if (params.id === 'new') {
      dispatch(actions.storeCamera(null));
      setEditEnabled(true);
    } else {
      dispatch(actions.fetchCamera(parseInt(params.id)));
      setEditEnabled(false);
    }
    dispatch(actions.fetchLocations());
  }, [params.id]);

  useEffect(() => {
    if (formik.values.protocol === 'railway_json' && !formik.values.railway_speed_limit) {
      formik.setFieldValue('railway_speed_limit', '');
    }
  }, [formik.values.protocol]);

  const handleEdit = () => {
    setEditEnabled(true);
  };

  const handleSave = () => {
    formik.submitForm();
  };

  const handleCancel = () => {
    formik.resetForm();
    params.id === 'new' ? onClose() : setEditEnabled(false);
  };

  const handleDelete = () => {
    dispatch(actions.deleteCamera(parseInt(params.id)));
    onClose();
  };

  const handleOutsideClick = event => {
    if (editEnabledRef.current) {
      setEditEnabled(false);
      if (confirm(I18n.t('settings.form_close_confirm'))) {
        onClose();
      } else {
        setEditEnabled(true);
        event.stopPropagation();
      }
    } else {
      onClose();
    }
  };

  const handleMarkerDragEnd = event => {
    formik.setFieldValue('latitude', event.latLng.lat());
    formik.setFieldValue('longitude', event.latLng.lng());
  };

  return (
    <>
      <CameraFormPanel>
        {isLoading ? (
          <div className="loading-box">
            <Loading />
          </div>
        ) : (
          <OutsideClickHandler
            className="click-handler-wrapper"
            onOutsideClick={handleOutsideClick}
            disabled={!outsideClickEnabled}
          >
            <div className="form-header">
              <h5>
                <div className="static-title">{I18n.t('settings.fixed_camera_title')}</div>
                {' - '.concat(params.id === 'new' ? I18n.t('settings.new_title') : camera?.name)}
              </h5>
              <div className="close-button" onClick={onClose}>
                <img src={Close} alt="close" />
              </div>
            </div>

            <div className="form-body">
              <div className="left-side">
                <FormField
                  label={I18n.t('settings.field_protocol_label')}
                  field={
                    <select
                      id="protocol"
                      name="protocol"
                      onChange={formik.handleChange}
                      value={formik.values.protocol ?? ''}
                      disabled={!editEnabled}
                    >
                      {protocolOptions.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  }
                  error={formik.errors.protocol}
                />
                <FormField
                  label={I18n.t('settings.field_name_label')}
                  field={
                    <input
                      id="name"
                      name="name"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name ?? ''}
                      disabled={!editEnabled}
                    />
                  }
                  error={formik.touched.name && formik.errors.name}
                />
                {!JSON_PROTOCOLS.includes(formik.values.protocol) && (
                  <>
                    <FormField
                      label={I18n.t('settings.field_ip_label')}
                      field={
                        <input
                          id="ip_addr"
                          name="ip_addr"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.ip_addr ?? ''}
                          disabled={!editEnabled}
                        />
                      }
                      error={formik.touched.ip_addr && formik.errors.ip_addr}
                    />
                    <FormField
                      label={I18n.t('settings.field_port_label')}
                      field={
                        <input
                          id="port"
                          name="port"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.port ?? ''}
                          disabled={!editEnabled}
                        />
                      }
                      error={formik.touched.port && formik.errors.port}
                    />
                  </>
                )}
                <FormField
                  label={I18n.t('settings.field_rotation_label')}
                  field={
                    <input
                      id="rotation"
                      name="rotation"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.rotation ?? ''}
                      disabled={!editEnabled}
                    />
                  }
                  error={formik.touched.rotation && formik.errors.rotation}
                />
                <FormField
                  label={I18n.t('settings.field_status_label')}
                  field={
                    <select
                      id="status"
                      name="status"
                      onChange={formik.handleChange}
                      value={formik.values.status ?? ''}
                      disabled={!editEnabled}
                    >
                      {statusOptions.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  }
                  error={formik.errors.status}
                />
                {formik.values.protocol !== ANPR_XML && (
                  <FormField
                    label={I18n.t('settings.field_mac_address_label')}
                    field={
                      <input
                        id="mac_address"
                        name="mac_address"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.mac_address ?? ''}
                        disabled={!editEnabled}
                      />
                    }
                    error={formik.touched.mac_address && formik.errors.mac_address}
                  />
                )}
                <FormField
                  label={I18n.t('settings.field_tssma_label')}
                  field={
                    <LabelToggle
                      name="tiny_system_states_message_active"
                      isOn={formik.values.tiny_system_states_message_active ?? false}
                      disabled={!editEnabled}
                      onChange={formik.handleChange}
                    />
                  }
                  error={formik.errors.tiny_system_states_message_active}
                />
                <FormField
                  label={I18n.t('settings.field_latitude_label')}
                  field={
                    <input
                      id="latitude"
                      name="latitude"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.latitude ?? ''}
                      disabled
                    />
                  }
                  error={formik.touched.latitude && formik.errors.latitude}
                />
                <FormField
                  label={I18n.t('settings.field_longitude_label')}
                  field={
                    <input
                      id="longitude"
                      name="longitude"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.longitude ?? ''}
                      disabled
                    />
                  }
                  error={formik.touched.longitude && formik.errors.longitude}
                />
                <FormField
                  label={I18n.t('settings.field_road_type_id_label')}
                  field={
                    <select
                      id="road_type_id"
                      name="road_type_id"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.road_type_id ?? ''}
                      disabled={!editEnabled}
                    >
                      <option />
                      {state.roadTypes?.map((option, index) => (
                        <option key={index} value={option.code}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                  }
                  error={formik.touched.road_type_id && formik.errors.road_type_id}
                />
                <FormField
                  label={I18n.t('settings.field_within_city_limits_label')}
                  field={
                    <LabelToggle
                      name="within_city_limits"
                      isOn={formik.values.within_city_limits ?? false}
                      disabled={!editEnabled}
                      onChange={formik.handleChange}
                    />
                  }
                  error={formik.errors.within_city_limits}
                />
                <FormField
                  label={I18n.t('settings.field_location_label')}
                  field={
                    <select
                      id="location"
                      name="location"
                      onChange={formik.handleChange}
                      value={formik.values.location ?? ''}
                      disabled={!editEnabled}
                    >
                      <option value=""></option>
                      {state?.locations?.map((option, index) => (
                        <option key={index} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                  }
                  error={formik.errors.location}
                />
                <FormField
                  label={I18n.t('settings.field_street_label')}
                  field={
                    <input
                      id="street"
                      name="street"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.street ?? ''}
                      disabled={!editEnabled}
                    />
                  }
                  error={formik.touched.street && formik.errors.street}
                />
                <FormField
                  label={I18n.t('settings.field_city_label')}
                  field={
                    <input
                      id="city"
                      name="city"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.city ?? ''}
                      disabled={!editEnabled}
                    />
                  }
                  error={formik.touched.city && formik.errors.city}
                />
                <FormField
                  label={I18n.t('settings.field_municipality_label')}
                  field={
                    <input
                      id="municipality"
                      name="municipality"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.municipality ?? ''}
                      disabled={!editEnabled}
                    />
                  }
                  error={formik.touched.municipality && formik.errors.municipality}
                />
                <FormField
                  label={I18n.t('settings.field_feeds_whitelist_label')}
                  field={
                    <LabelToggle
                      name="feeds_whitelist"
                      fg
                      isOn={formik.values.feeds_whitelist ?? false}
                      disabled={!editEnabled}
                      onChange={formik.handleChange}
                    />
                  }
                  error={formik.errors.feeds_whitelist}
                />
                {formik.values.protocol === 'railway_json' && (
                  <>
                    <p>
                      <strong>{I18n.t('settings.railway_settings')}</strong>
                    </p>
                    <FormField
                      label={I18n.t('settings.field_railway_speed_limit')}
                      field={
                        <select
                          id="railway_speed_limit"
                          name="railway_speed_limit"
                          onChange={formik.handleChange}
                          value={formik.values.railway_speed_limit ?? ''}
                          disabled={!editEnabled}
                        >
                          {railwaySpeedLimitOptions.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      }
                      error={
                        formik.touched.railway_speed_limit && formik.errors.railway_speed_limit
                      }
                    />
                    <FormField
                      label={I18n.t('settings.field_railway_moped_on_main_road')}
                      field={
                        <LabelToggle
                          id="railway_moped_on_main_road"
                          name="railway_moped_on_main_road"
                          isOn={formik.values.railway_moped_on_main_road ?? false}
                          disabled={!editEnabled}
                          onChange={formik.handleChange}
                        />
                      }
                      error={formik.errors.railway_moped_on_main_road}
                    />
                  </>
                )}

                {!JSON_PROTOCOLS.includes(formik.values.protocol) && (
                  <>
                    <FormField
                      label={I18n.t('settings.field_uses_legacy_daemon')}
                      field={
                        <LabelToggle
                          name="uses_legacy_daemon"
                          isOn={formik.values.uses_legacy_daemon ?? false}
                          disabled={!editEnabled}
                          onChange={formik.handleChange}
                        />
                      }
                      error={formik.errors.uses_legacy_daemon}
                    />
                  </>
                )}
              </div>
              <div className="right-side">
                <CameraFormMap
                  camera={formik.values}
                  onMarkerDragEnd={handleMarkerDragEnd}
                  draggable={editEnabled}
                />
                <CameraFormOverviewImage
                  formik={formik}
                  editEnabled={editEnabled}
                  removeOverviewImage={removeOverviewImage}
                />
              </div>
            </div>

            {hasWritePermission && (
              <FormControls
                editEnabled={editEnabled}
                handleEdit={handleEdit}
                handleSave={handleSave}
                handleCancel={handleCancel}
                handleDelete={() => setShowDeleteConfirmation(true)}
                isDeletable={params.id !== 'new'}
              />
            )}
          </OutsideClickHandler>
        )}
      </CameraFormPanel>

      {showDeleteConfirmation && (
        <InputConfirmationModal
          show={showDeleteConfirmation}
          onHide={() => setShowDeleteConfirmation(false)}
          message={I18n.t('settings.form_delete_confirm', { word: 'DELETE' })}
          confirmMessage={'DELETE'}
          cancelBtn={{
            label: I18n.t('settings.form_cancel_button'),
            onClick: () => setShowDeleteConfirmation(false)
          }}
          okBtn={{
            label: I18n.t('settings.form_ok_button'),
            onClick: handleDelete
          }}
        />
      )}

      {backendValidationError && <Alert variant="danger">{backendValidationError}</Alert>}
    </>
  );
};

export default CameraForm;
