import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Ticket from './components/scenes/Ticket';
import Tickets from './components/scenes/Tickets';
import Observations from './components/scenes/Observations';
import Settings from './components/scenes/Cop/Settings';
import Suspects from './components/scenes/Cop/Suspects';
import PowerBIPage from './components/scenes/PowerBIPage';

// TODO: COP remove type scancar
const Routes = () => {
  return (
    <Switch>
      <Route path="/tickets/new/:type?">
        <Ticket />
      </Route>
      <Route path="/tickets/:id/edit">
        <Ticket />
      </Route>
      <Route path="/tickets/:id">
        <Ticket readOnly />
      </Route>
      <Route path="/tickets">
        <Tickets />
      </Route>
      <Route path="/scancar/areas">
        <Observations type="scancar" />
      </Route>
      <Route path="/c01/areas">
        <Observations type="c01" />
      </Route>
      <Route path="/cop/camera_status">
        <PowerBIPage pageName="camera_status" />
      </Route>
      <Route path="/cop/settings">
        <Settings />
      </Route>
      <Route path="/cop">
        <Suspects />
      </Route>
      <Route path="/reports">
        <PowerBIPage pageName="reports" />
      </Route>
    </Switch>
  );
};

export default Routes;
