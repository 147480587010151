import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import MenuItem from '../../../components/MenuItem';

const StyledUseCaseSubMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 100%;
`;

function UseCaseSubMenu({ isEdit }) {
  const match = useRouteMatch();

  const menuItems = [
    { link: `${match.url}/details`, label: I18n.t('settings.use_case_details_label') }
  ];

  if (isEdit) {
    menuItems.push({
      link: `${match.url}/cameras`,
      label: I18n.t('settings.use_case_cameras_label')
    });
    menuItems.push({
      link: `${match.url}/pipelines`,
      label: I18n.t('settings.use_case_pipelines_label')
    });
  }

  const isItemSelected = item => {
    return location.pathname.match(`${item.link}`);
  };

  return (
    <StyledUseCaseSubMenu>
      {menuItems.map(item => (
        <MenuItem
          key={item.label}
          link={item.link}
          label={item.label}
          isSelected={isItemSelected(item)}
        />
      ))}
    </StyledUseCaseSubMenu>
  );
}

export default UseCaseSubMenu;
