import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Close from '@/assets/img/close_icon.svg';
import { useMobileCamerasSlice } from '../slice';
import { Loading, Alert } from 'brickyard-ui';
import { useFormik } from 'formik';
import FormField from '../../../components/FormField';
import LabelToggle from '../../../../../Ticket/Cards/components/LabelToggle';
import OutsideClickHandler from '../../../../../../OutsideClickHandler';
import mobileCameraSchema from '../validationSchema';
import InputConfirmationModal from '../../../components/InputConfirmationModal';
import FormControls from '../../../components/FormControls';
import { COP_PERM_WRITE, JSON_PROTOCOLS } from '../../../../../../../utils/consts';

const CameraFormPanel = styled.div`
  display: flex;
  width: calc(100% * 2 / 3 - 60px);
  height: calc(100% - 60px);
  background-color: #ffffff;
  margin: 30px;
  border: 1px solid #e5e5e5;

  .loading-box {
    margin: auto;
  }

  .click-handler-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .form-header {
      display: flex;
      width: (100% -80px);
      margin: 40px;
      height: 50px;

      h3 {
        display: flex;
        color: #4a4a49;
        font-weight: 600;
        margin: auto auto auto 10px;

        .static-title {
          font-weight: 300;
          margin-right: 7px;
        }
      }

      .close-button {
        margin: auto 10px auto auto;
        cursor: pointer;
      }
    }

    .form-body {
      display: flex;
      flex-direction: column;
      width: calc(50% - 40px);
      height: calc(100% - 260px);
      margin: 40px;
      margin-top: 0px;
      overflow-y: auto;
    }
  }
`;

const CameraForm = ({ camera, onClose, isLoading, backendValidationError, _permission }) => {
  const { actions } = useMobileCamerasSlice();
  const dispatch = useDispatch();
  const params = useParams();

  const user = useSelector(state => state.user);
  const hasWritePermission = user?.cop_settings_permission === COP_PERM_WRITE;

  const [editEnabled, setEditEnabled] = useState(false);
  const editEnabledRef = useRef();
  editEnabledRef.current = editEnabled;

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [outsideClickEnabled, setOutsideClickEnabled] = useState(false);
  useEffect(() => {
    setOutsideClickEnabled(!showDeleteConfirmation);
  }, [showDeleteConfirmation]);

  const formik = useFormik({
    initialValues: camera ?? {
      protocol: 'scancar_xml',
      name: '',
      ip_addr: '',
      port: '',
      status: 'disabled',
      mac_address: '',
      tiny_system_states_message_active: false,
      feeds_whitelist: false,
      find_nearest_zone: false,
      nearest_zone_threshold_cm: '',
      uses_legacy_daemon: true
    },
    enableReinitialize: true,
    onSubmit: values => {
      const propsToRemove = ['id', 'longitude', 'latitude', 'updated_at', 'created_at'];

      const filteredValues = Object.keys(values)
        .filter(key => !propsToRemove.includes(key))
        .reduce((obj, key) => {
          return { ...obj, [key]: values[key] };
        }, {});

      if (params.id === 'new') {
        dispatch(
          actions.createCamera({
            type: 'Cop::MobileCamera',
            props: filteredValues,
            onSuccess: () => {
              onClose();
            }
          })
        );
      } else {
        dispatch(
          actions.updateCamera({
            id: parseInt(params.id),
            props: filteredValues,
            onSuccess: () => {
              onClose();
            }
          })
        );
      }
    },
    validationSchema: mobileCameraSchema
  });

  const protocolOptions = [
    { value: 'scancar_xml', label: I18n.t('settings.protocol_scancar_xml_label') },
    { value: 'bop', label: I18n.t('settings.protocol_bop_label') }
  ];

  const statusOptions = [
    { value: 'disabled', label: I18n.t('settings.status_disabled_label') },
    { value: 'enabled', label: I18n.t('settings.status_enabled_label') }
  ];

  useEffect(() => {
    if (params.id === 'new') {
      dispatch(actions.storeCamera(null));
      setEditEnabled(true);
    } else {
      dispatch(actions.fetchCamera(parseInt(params.id)));
      setEditEnabled(false);
    }
  }, [params.id]);

  const handleEdit = () => {
    setEditEnabled(true);
  };

  const handleSave = () => {
    formik.submitForm();
  };

  const handleCancel = () => {
    formik.resetForm();
    params.id === 'new' ? onClose() : setEditEnabled(false);
  };

  const handleDelete = () => {
    dispatch(actions.deleteCamera(parseInt(params.id)));
    onClose();
  };

  const handleOutsideClick = event => {
    if (editEnabledRef.current) {
      setEditEnabled(false);
      if (confirm(I18n.t('settings.form_close_confirm'))) {
        onClose();
      } else {
        setEditEnabled(true);
        event.stopPropagation();
      }
    } else {
      onClose();
    }
  };

  return (
    <>
      <CameraFormPanel>
        {isLoading ? (
          <div className="loading-box">
            <Loading />
          </div>
        ) : (
          <OutsideClickHandler
            className="click-handler-wrapper"
            onOutsideClick={handleOutsideClick}
            disabled={!outsideClickEnabled}
          >
            <div className="form-header">
              <h3>
                <div className="static-title">{I18n.t('settings.mobile_camera_title')}</div>
                {' - '.concat(params.id === 'new' ? I18n.t('settings.new_title') : camera?.name)}
              </h3>
              <div className="close-button" onClick={onClose}>
                <img src={Close} alt="close" />
              </div>
            </div>

            <div className="form-body">
              <FormField
                label={I18n.t('settings.field_protocol_label')}
                field={
                  <select
                    id="protocol"
                    name="protocol"
                    onChange={formik.handleChange}
                    value={formik.values.protocol ?? ''}
                    disabled={!editEnabled}
                  >
                    {protocolOptions.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                }
                error={formik.errors.protocol}
              />
              <FormField
                label={I18n.t('settings.field_name_label')}
                field={
                  <input
                    id="name"
                    name="name"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name ?? ''}
                    disabled={!editEnabled}
                  />
                }
                error={formik.touched.name && formik.errors.name}
              />
              {!JSON_PROTOCOLS.includes(formik.values.protocol) && (
                <>
                  <FormField
                    label={I18n.t('settings.field_ip_label')}
                    field={
                      <input
                        id="ip_addr"
                        name="ip_addr"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.ip_addr ?? ''}
                        disabled={!editEnabled}
                      />
                    }
                    error={formik.touched.ip_addr && formik.errors.ip_addr}
                  />
                  <FormField
                    label={I18n.t('settings.field_port_label')}
                    field={
                      <input
                        id="port"
                        name="port"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.port ?? ''}
                        disabled={!editEnabled}
                      />
                    }
                    error={formik.touched.port && formik.errors.port}
                  />
                </>
              )}
              <FormField
                label={I18n.t('settings.field_status_label')}
                field={
                  <select
                    id="status"
                    name="status"
                    onChange={formik.handleChange}
                    value={formik.values.status ?? ''}
                    disabled={!editEnabled}
                  >
                    {statusOptions.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                }
                error={formik.errors.status}
              />
              {JSON_PROTOCOLS.includes(formik.values.protocol) && (
                <FormField
                  label={I18n.t('settings.field_mac_address_label')}
                  field={
                    <input
                      id="mac_address"
                      name="mac_address"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.mac_address ?? ''}
                      disabled={!editEnabled}
                    />
                  }
                  error={formik.touched.mac_address && formik.errors.mac_address}
                />
              )}
              <FormField
                label={I18n.t('settings.field_tssma_label')}
                field={
                  <LabelToggle
                    name="tiny_system_states_message_active"
                    isOn={formik.values.tiny_system_states_message_active ?? false}
                    disabled={!editEnabled}
                    onChange={formik.handleChange}
                  />
                }
                error={formik.errors.tiny_system_states_message_active}
              />
              <FormField
                label={I18n.t('settings.field_feeds_whitelist_label')}
                field={
                  <LabelToggle
                    name="feeds_whitelist"
                    isOn={formik.values.feeds_whitelist ?? false}
                    disabled={!editEnabled}
                    onChange={formik.handleChange}
                  />
                }
                error={formik.errors.feeds_whitelist}
              />
              <FormField
                label={I18n.t('settings.field_find_nearest_zone')}
                field={
                  <LabelToggle
                    name="find_nearest_zone"
                    isOn={formik.values.find_nearest_zone ?? false}
                    disabled={!editEnabled}
                    onChange={formik.handleChange}
                  />
                }
                error={formik.errors.find_nearest_zone}
              />

              {formik.values.find_nearest_zone && (
                <FormField
                  label={I18n.t('settings.field_nearest_zone_threshold')}
                  field={
                    <input
                      id="nearest_zone_threshold_cm"
                      name="nearest_zone_threshold_cm"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.nearest_zone_threshold_cm ?? ''}
                      disabled={!editEnabled}
                    />
                  }
                  error={
                    formik.touched.nearest_zone_threshold_cm &&
                    formik.errors.nearest_zone_threshold_cm
                  }
                />
              )}

              {!JSON_PROTOCOLS.includes(formik.values.protocol) && (
                <FormField
                  label={I18n.t('settings.field_uses_legacy_daemon')}
                  field={
                    <LabelToggle
                      name="uses_legacy_daemon"
                      isOn={formik.values.uses_legacy_daemon ?? false}
                      disabled={!editEnabled}
                      onChange={formik.handleChange}
                    />
                  }
                  error={formik.errors.uses_legacy_daemon}
                />
              )}
            </div>

            {hasWritePermission && (
              <FormControls
                editEnabled={editEnabled}
                handleEdit={handleEdit}
                handleSave={handleSave}
                handleCancel={handleCancel}
                handleDelete={() => setShowDeleteConfirmation(true)}
                isDeletable={params.id !== 'new'}
              />
            )}
          </OutsideClickHandler>
        )}
      </CameraFormPanel>

      {showDeleteConfirmation && (
        <InputConfirmationModal
          show={showDeleteConfirmation}
          onHide={() => setShowDeleteConfirmation(false)}
          message={I18n.t('settings.form_delete_confirm', { word: 'DELETE' })}
          confirmMessage={'DELETE'}
          cancelBtn={{
            label: I18n.t('settings.form_cancel_button'),
            onClick: () => setShowDeleteConfirmation(false)
          }}
          okBtn={{
            label: I18n.t('settings.form_ok_button'),
            onClick: handleDelete
          }}
        />
      )}

      {backendValidationError && (
        <Alert
          variant="danger"
          style={{
            position: 'fixed',
            left: '50%',
            transform: 'translate(-50%, 0)',
            width: '80%',
            marginTop: '20px',
            textAlign: 'center'
          }}
        >
          {backendValidationError}
        </Alert>
      )}
    </>
  );
};

export default CameraForm;
