import React, { useState, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { useQuery } from 'react-apollo';
import { pick, omit } from 'ramda';

import { Button, Loading, ContinuousPagination, Alert, Col, Row, Container } from 'brickyard-ui';

import withApolloProvider from '@/utils/withApolloProvider';
import { CamelCaseToUnderscore, URLToObject } from '@/utils/URLQueryParser';

import { GET_TICKETS } from '@/components/queries/tickets';
import TicketsSearch, {
  i18nAllSearchFields,
  temporaryFiltersKeys
} from './TicketsTable/TicketsSearch';
import TicketsTable from '@/components/scenes/TicketsTable/TicketsTable';

import Api from '@/utils/Api';

import LeftMenu from './LeftMenu';

import 'styles/scenes/tickets.scss';
import useURLBind from '@/hooks/useURLBind';
import useContinuousPagination from '@/hooks/useContinuousPagination';
import NewTicketModal from './components/NewTicketModal';
import { GET_TICKET_CREATE_PERMISSION } from '@/components/queries/tickets';
import { GET_HIDDEN_TICKETS } from '../queries/tickets';
import { TICKETS_FILTER_KEY, TICKETS_PAGINATION_KEY } from '../../utils/consts';

const sortMap = {
  blockState: 'BLOCK_STATE',
  dismissalState: 'DISMISSAL_STATE',
  exportState: 'EXPORT_STATE',
  isWarning: 'IS_WARNING',
  offenseCode: 'OFFENSE_CODE',
  paymentState: 'PAYMENT_STATE',
  pvNumber: 'PV_NUMBER',
  state: 'STATE',
  suspectLastname: 'SUSPECT_LASTNAME',
  vehicleLicensePlateNumber: 'VEHICLE_LICENSE_PLATE_NUMBER',
  writtenAt: 'WRITTEN_AT',
  id: 'ID'
};

const sortStringToObj = str => {
  const order = str.endsWith('_ASC') ? 'asc' : 'desc';
  return {
    key: Object.keys(sortMap).find(
      k => sortMap[k] === str.replace('_ASC', '').replace('_DESC', '')
    ),
    order
  };
};

const sortObjToString = obj => (obj ? `${sortMap[obj.key]}_${obj.order.toUpperCase()}` : '');

const threeMonthsAgo = dayjs()
  .subtract(3, 'month')
  .toISOString();

const Tickets = () => {
  const ticketsFilter = localStorage.getItem(TICKETS_FILTER_KEY);
  const ticketsPagination = JSON.parse(localStorage.getItem(TICKETS_PAGINATION_KEY));
  const pagination = useContinuousPagination();
  const defaultSearch = useMemo(
    () =>
      Object.keys(omit(['s'], URLToObject(window.location))).length === 0
        ? { writtenAtGteq: threeMonthsAgo, s: 'WRITTEN_AT_DESC' }
        : ticketsFilter
        ? JSON.parse(ticketsFilter)
        : {},
    []
  );
  const [urlSearch, setURLSearch] = useURLBind(defaultSearch);
  const [search, setSearch] = useState({});
  const [historySearch, setHistorySearch] = useState(null);
  const [newTicketDialogVisible, setNewTicketDialogVisibility] = useState(false);
  const [sort, setSort] = useState(null);
  const { loading, data, error } = useQuery(GET_TICKETS, {
    variables: {
      first: pagination.first,
      last: pagination.last,
      after:
        typeof ticketsPagination?.after !== 'undefined'
          ? ticketsPagination?.after
          : pagination.after,
      before:
        typeof ticketsPagination?.before !== 'undefined'
          ? ticketsPagination?.before
          : pagination.before,
      search: omit(['temporaryFilters'], search)
    },
    skip: historySearch
  });

  const { loading: historyLoading, data: historyData, error: historyError } = useQuery(
    GET_HIDDEN_TICKETS,
    {
      variables: {
        search: historySearch
      },
      skip: !historySearch
    }
  );

  const {
    data: permissionData,
    loading: loadingPermissions
  } = useQuery(GET_TICKET_CREATE_PERMISSION, { variables: {} });

  /**
   * Set pagination size according to the container size
   */
  useEffect(() => {
    setPaginationSizes(pagination);
  });

  const setPaginationSizes = pagination => {
    if (process.env.NODE_ENV === 'test') {
      return;
    }
    const containerHeight = document.querySelector('#tickets').clientHeight;
    const otherComponentsHeight = 300;
    const rowHeight = 35;

    pagination.setSize(parseInt((containerHeight - otherComponentsHeight) / rowHeight));
  };

  /**
   * This useEffect listens to the urlSearch variable (bound to the URL) and sets a valid search object.
   * A valid search object only contains fields allowed on GraphQL, check the TicketSearch type.
   */
  useEffect(() => {
    let searchObj = pick(['s', ...Object.keys(i18nAllSearchFields)], urlSearch);

    if (searchObj.temporaryFilters != undefined) {
      const allowedSearchObj = omit(temporaryFiltersKeys, searchObj);
      localStorage.setItem(TICKETS_FILTER_KEY, JSON.stringify(allowedSearchObj));
    } else {
      localStorage.setItem(TICKETS_FILTER_KEY, JSON.stringify(searchObj));
    }

    setSearch(searchObj);
  }, [urlSearch]);

  /**
   * The following useEffects are used to sync the table and the search object.
   *
   * The search object is bound to the URL, so it needs to load the sort from there
   * and then set to the table during the first rendering. When the table triggers the sort,
   * it needs the other way around, it needs to set the sort attribute on the search object.
   *
   * The useEffects here are doing this job, the first handles the change on search when the sort
   * comes form the table click, the second reflects the url changes and loading to the sort object and
   * consequently to the table.
   *
   */

  useEffect(() => {
    if (sort && sort !== search.s) {
      setURLSearch({ ...search, s: sort });
    }
  }, [sort]);

  useEffect(() => {
    if (urlSearch.s !== sort) {
      setSort(urlSearch.s);
    }
  }, [urlSearch]);

  useEffect(() => {
    if (urlSearch.before) {
      pagination.previous(urlSearch.before);
    } else if (urlSearch.after) {
      pagination.next(urlSearch.after);
    } else {
      pagination.start();
    }
  }, [urlSearch]);

  useEffect(() => {
    const { before, after } = pagination;
    const url = new URL(window.location);
    if (before) {
      url.searchParams.set('before', before);
    } else {
      url.searchParams.delete('before');
    }
    if (after) {
      url.searchParams.set('after', after);
    } else {
      url.searchParams.delete('after');
    }
    window.history.pushState({}, null, url);
  }, [pagination.before, pagination.after]);

  const downloadCsv = async event => {
    let button = event.target;
    let buttonHtml = button.innerHTML;
    button.disabled = true;
    button.innerHTML =
      '<div class="spinner-border spinner-border-sm" role="status">\n' +
      '  <span class="sr-only">Loading...</span>\n' +
      '</div>';

    let searchParams = CamelCaseToUnderscore(search);
    const csv = await Api.get(`/tickets/`, {
      params: {
        q: { ...searchParams }
      },
      timeout: 0,
      headers: { Accept: 'text/csv' }
    });

    const url = window.URL.createObjectURL(new Blob([csv.data]));
    const a = document.createElement('a');
    a.href = url;
    a.setAttribute('download', 'tickets.csv');

    const clickHandler = () => {
      window.setTimeout(() => {
        window.URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();

    button.disabled = false;
    button.innerHTML = buttonHtml;
  };

  const tickets = historySearch ? historyData?.hiddenTickets : data?.tickets;

  return (
    <div className="app-container">
      <LeftMenu />
      <div id="tickets">
        <TicketsSearch
          onSearch={filters => {
            setHistorySearch(null);
            setURLSearch({ ...filters, s: sort || 'WRITTEN_AT_DESC' });
          }}
          value={search}
          historySearch={historySearch}
          onHistorySearch={filters => {
            setHistorySearch(filters);
          }}
        />
        {loading || historyLoading ? (
          <Loading />
        ) : error || historyError ? (
          <Alert variant="danger">{(error || historyError).toString()}</Alert>
        ) : (
          <>
            <Container fluid className="pb-3 px-0">
              <Row>
                <Col>
                  {historySearch ? (
                    <span className="history-search-description">
                      {I18n.t('tickets.history_search_description')}
                    </span>
                  ) : (
                    <span>
                      {I18n.t('tickets.total', {
                        results: tickets.nodes.length,
                        total: tickets.totalCount
                      })}
                    </span>
                  )}
                </Col>
                <Col sm="3" className="text-right pr-0">
                  <Button onClick={downloadCsv} variant="by-secondary" className="mr-2">
                    {I18n.t('tickets.actions.export_to_csv')}
                  </Button>
                </Col>
              </Row>
            </Container>
            <TicketsTable
              tickets={tickets.nodes}
              onSort={s => setSort(sortObjToString(s))}
              sorting={sort ? sortStringToObj(sort) : { key: 'writtenAt', order: 'desc' }}
              isHistorySearch={!!historySearch}
            />
            <Container fluid>
              <Row>
                <Col></Col>
                <Col sm="6">
                  {!historySearch && (
                    <div className="d-flex flex-row justify-content-center">
                      <ContinuousPagination
                        hasPrevious={tickets.pageInfo?.hasPreviousPage}
                        hasNext={tickets.pageInfo?.hasNextPage}
                        onStart={() => {
                          localStorage.removeItem(TICKETS_PAGINATION_KEY);
                          pagination.start();
                        }}
                        onPrevious={() => {
                          localStorage.setItem(
                            TICKETS_PAGINATION_KEY,
                            JSON.stringify({
                              before: tickets.pageInfo?.startCursor
                            })
                          );
                          pagination.previous(tickets.pageInfo?.startCursor);
                        }}
                        onNext={() => {
                          localStorage.setItem(
                            TICKETS_PAGINATION_KEY,
                            JSON.stringify({
                              after: tickets.pageInfo?.endCursor
                            })
                          );
                          pagination.next(tickets.pageInfo?.endCursor);
                        }}
                        onEnd={() => {
                          localStorage.removeItem(TICKETS_PAGINATION_KEY);
                          pagination.end();
                        }}
                      />
                    </div>
                  )}
                </Col>
                <Col className="pr-0 text-right">
                  {!loadingPermissions &&
                    permissionData !== undefined &&
                    permissionData.ticketPermission.create && (
                      <Button
                        variant="by-primary"
                        onClick={() => setNewTicketDialogVisibility(true)}
                      >
                        {I18n.t('tickets.dialogs.new_ticket.title')}
                      </Button>
                    )}
                </Col>
              </Row>
            </Container>
          </>
        )}
      </div>
      <NewTicketModal
        show={newTicketDialogVisible}
        onHide={() => setNewTicketDialogVisibility(false)}
      />
    </div>
  );
};

export default withApolloProvider(Tickets);
