import React from 'react';
import gql from 'graphql-tag';
import UseCaseResultProcessItem from '../shared/observations/UseCaseResultProcessItem';
import { useQuery, useMutation } from 'react-apollo';
import { Modal, Alert, Card, Button, Accordion, Loading } from 'brickyard-ui';
import { GET_MIN_USE_CASE_RESULT } from '@/components/queries/cop/use_case_results';
import { RESET_USE_CASE_RESULTS } from '../mutations/cop/use_case_results';

export const PROCESS_VALIDATED = gql`
  mutation processValidated($useCaseId: ID!) {
    copProcessValidated(useCaseId: $useCaseId) {
      id
    }
  }
`;

const UseCaseResultProcessSummary = ({
  useCaseId,
  observations,
  onHide,
  onProcess,
  onReset,
  onResetAll,
  show
}) => {
  const sections = new Set(
    Object.values(observations)
      .map(meta => {
        return meta.action?.type ? meta.action.type : meta.action;
      })
      .filter(Boolean)
  );

  const { loading, error, data } = useQuery(GET_MIN_USE_CASE_RESULT, {
    variables: { id: Object.keys(observations), useCaseId, first: Object.keys(observations).length }
  });

  const [processValidated, { loading: processingLoading, error: creatingErr }] = useMutation(
    PROCESS_VALIDATED
  );

  const [resetAll, { loading: resetAllLoading, error: resetAllErr }] = useMutation(
    RESET_USE_CASE_RESULTS,
    {
      variables: {
        ids: Object.keys(observations)
      },
      onCompleted: onResetAll
    }
  );

  const handleProcess = async () => {
    await processValidated({
      variables: { useCaseId }
    });
    onProcess();
    onHide();
  };

  const getUseCaseResultAction = ucr => {
    return ucr.action?.type ? ucr.action.type : ucr.action;
  };

  if (error || loading) return null;

  if (!observations || !Object.values(observations)) {
    onHide();
    return null;
  }

  return (
    <Modal show={show} onHide={onHide} size="xl" className="observation-process-summary">
      <Modal.Body>
        {' '}
        <Accordion defaultActiveKey="-1">
          {Array.from(sections).map((action, idx) => (
            <Card size="sm" key={action}>
              <Card.Header>
                <Accordion.Toggle eventKey={`${idx}`} as="span">
                  {I18n.t(
                    `observations.observations.details.${
                      action === 'delete' ? 'actions.delete' : `to_${action}`
                    }`
                  )}{' '}
                  (
                  {
                    Object.values(observations).filter(o => getUseCaseResultAction(o) === action)
                      .length
                  }
                  )
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey={`${idx}`}>
                <Card.Body>
                  {data &&
                    data.useCaseResults.nodes
                      .filter(
                        o =>
                          (observations[o.id] && getUseCaseResultAction(observations[o.id])) ===
                          action
                      )
                      .map(o => (
                        <UseCaseResultProcessItem
                          useCaseResult={o}
                          onReset={onReset}
                          action={action}
                          key={o.id}
                        />
                      ))}

                  {resetAllErr && <Alert variant="danger">{resetAllErr.toString()}</Alert>}
                  {creatingErr && <Alert variant="danger">{creatingErr.toString()}</Alert>}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          ))}
        </Accordion>
      </Modal.Body>
      <Modal.Footer className="summary-footer">
        <Button variant="danger" onClick={resetAll} disabled={resetAllLoading || processingLoading}>
          {I18n.t('observations.observations.process.reset_all')}
        </Button>

        <Button variant="by-dark" onClick={onHide} disabled={resetAllLoading || processingLoading}>
          {I18n.t('actions.cancel')}
        </Button>

        <Button
          variant="by-primary"
          onClick={handleProcess}
          disabled={resetAllLoading || processingLoading}
        >
          {I18n.t('observations.observations.process.process')}
          {processingLoading && <Loading size="sm" />}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UseCaseResultProcessSummary;
