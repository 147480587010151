import React from 'react';
import styled from 'styled-components';
import FormField from '../../../components/FormField';
import FormFieldBlock from '../../../components/FormFieldBlock';
import { Button } from 'brickyard-ui';

const StyledCameraFormOverviewImage = styled.div`
  display: flex;
  flex-direction: column;
`;

function CameraFormOverviewImage({ formik, editEnabled, removeOverviewImage }) {
  return (
    <StyledCameraFormOverviewImage>
      <FormField
        label={I18n.t('settings.field_fixed_camera_overview_image')}
        field={
          <input
            id="overview_image"
            name="overview_image"
            type="file"
            accept="image/*"
            disabled={!editEnabled}
            onChange={event => {
              formik.setFieldValue(
                'overview_image_url',
                URL.createObjectURL(event.currentTarget.files[0])
              );
              formik.setFieldValue('overview_image', event.currentTarget.files[0]);
            }}
          />
        }
        error={formik.errors.overview_image}
      />
      {formik.values.overview_image_url && !formik.errors.overview_image && (
        <FormFieldBlock
          field={
            <>
              <div>
                <img src={formik.values.overview_image_url} height={200} />
              </div>
              <div>
                <Button
                  className="btn btn-danger mt-2"
                  onClick={async () =>
                    await removeOverviewImage().then(() => {
                      formik.setFieldValue('overview_image', null);
                      formik.setFieldValue('overview_image_url', null);
                    })
                  }
                  disabled={!editEnabled}
                >
                  {I18n.t('settings.field_fixed_camera_overview_image_remove')}
                </Button>
              </div>
            </>
          }
        />
      )}
    </StyledCameraFormOverviewImage>
  );
}

export default CameraFormOverviewImage;
