import React, { useEffect, useImperativeHandle } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import Scheduler from './components/Scheduler';
import { useDispatch, useSelector } from 'react-redux';
import FixedCameraField from '../fields/FixedCameraField';
import ScanCarField from '../fields/ScanCarField';
import {
  VEHICLE_DIRECTIONS,
  VEHICLE_EMISSION_CODES,
  VEHICLE_EUROPEAN_CATEGORIES,
  VEHICLE_FUEL_TYPES,
  VEHICLE_INFO_RELATED_FIELDS
} from '../../../../../../../../../utils/consts';
import VehicleTypeField from '../fields/VehicleTypeField';
import DirectionField from '../fields/DirectionField';
import EmissionCodeField from '../fields/EmissionCodeField';
import EuropeanCategoryField from '../fields/EuropeanCategoryField';
import FuelTypeField from '../fields/FuelTypeField';
import CountryField from '../fields/CountryField';
import FormFieldSmall from '../../../../../components/FormFieldSmall';
import LabelToggle from '../../../../../../../Ticket/Cards/components/LabelToggle';
import VehicleBuildYearField from '../fields/VehicleBuildYearField';
import VehicleWidthField from '../fields/VehicleWidthField';
import VehicleWeightField from '../fields/VehicleWeightField';
import MakesExemptField from '../fields/MakesExemptField';
import validationSchema from './validationSchema';
import FormFieldBlock from '../../../../../components/FormFieldBlock';
import { selectUseCasesState, useUseCasesSlice } from '../../../slice';
import { Loading } from 'brickyard-ui';
import { useQuery } from 'react-apollo';
import { GET_VEHICLE_TYPES } from '../../../../../../../../queries/SharedQueries';

const StyledExemptionRuleChecker = styled.div`
  .edit-link {
    cursor: pointer;
    color: #007bff;
    margin-left: 10px;

    &:hover {
      color: #0056b3;
      text-decoration: underline;
    }
  }

  .error {
    color: #d4666b;
    margin-left: 10px;
    size: 12px;
  }

  .content {
    max-height: 680px;
    overflow-x: auto;
  }
`;

function ExemptionRuleChecker({ outerRef, editEnabled, persistPipelineProcessor }) {
  const { selectedUseCase: useCase, selectedProcessor, countries } = useSelector(
    selectUseCasesState
  );
  const { actions } = useUseCasesSlice();

  const selectedFixedCameras = selectedProcessor?.config?.rule?.camera_ids
    ? useCase?.cameras?.filter(camera =>
        selectedProcessor?.config?.rule?.camera_ids?.find(
          selectedCamera => selectedCamera == camera.id && camera.type == 'Cop::FixedCamera'
        )
      )
    : [];

  const selectedMobileCameras = selectedProcessor?.config?.rule?.camera_ids
    ? useCase?.cameras?.filter(camera =>
        selectedProcessor?.config?.rule?.camera_ids?.find(
          selectedCamera => selectedCamera == camera.id && camera.type == 'Cop::MobileCamera'
        )
      )
    : [];

  const availableCameras = useCase?.cameras?.filter(
    camera =>
      !selectedProcessor?.config?.rule?.camera_ids?.find(
        selectedCamera => selectedCamera == camera.id
      )
  );

  const { loading: vehicleTypesLoading, data: vehicleTypesData } = useQuery(GET_VEHICLE_TYPES, {
    variables: { needRdwConst: true }
  });

  const selectedVehicleTypes = selectedProcessor?.config?.rule?.vehicle_types
    ? vehicleTypesData
      ? vehicleTypesData.vehicleTypes
          ?.filter(type =>
            selectedProcessor?.config?.rule?.vehicle_types?.find(
              selectedType => selectedType == type.rawName
            )
          )
          .map(type => ({
            id: type.rawName,
            name: type.rawName
          }))
      : []
    : [];

  const availableVehicleTypes = vehicleTypesData
    ? vehicleTypesData.vehicleTypes
        ?.filter(
          type =>
            !selectedVehicleTypes?.find(
              selectedVehicleType => selectedVehicleType.id === type.rawName
            )
        )
        .map(type => ({
          id: type.rawName,
          name: type.rawName
        }))
    : [];

  const selectedDirections = selectedProcessor?.config?.rule?.vehicle_directions
    ? VEHICLE_DIRECTIONS?.filter(direction =>
        selectedProcessor?.config?.rule?.vehicle_directions?.find(
          selectedDirection => selectedDirection == direction.id
        )
      )
    : [];

  const availableDirections = VEHICLE_DIRECTIONS?.filter(
    direction =>
      !selectedDirections?.find(selectedDirection => selectedDirection.id === direction.id)
  );

  const selectedEmissionCodes = selectedProcessor?.config?.rule?.emission_codes
    ? VEHICLE_EMISSION_CODES?.filter(code =>
        selectedProcessor?.config?.rule?.emission_codes?.find(
          selectedCode => selectedCode == code.id
        )
      )
    : [];

  const availableEmissionCodes = VEHICLE_EMISSION_CODES?.filter(
    code => !selectedEmissionCodes?.find(selectedCode => selectedCode.id === code.id)
  );

  const selectedEuropeanCategories = selectedProcessor?.config?.rule?.european_categories
    ? VEHICLE_EUROPEAN_CATEGORIES?.filter(category =>
        selectedProcessor?.config?.rule?.european_categories?.find(
          selectedCategory => selectedCategory == category.id
        )
      )
    : [];

  const availableEuropeanCategories = VEHICLE_EUROPEAN_CATEGORIES?.filter(
    category =>
      !selectedEuropeanCategories?.find(selectedCategory => selectedCategory.id === category.id)
  );

  const selectedFuelTypes = selectedProcessor?.config?.rule?.types_of_fuel
    ? VEHICLE_FUEL_TYPES?.filter(fuel =>
        selectedProcessor?.config?.rule?.types_of_fuel?.find(
          selectedFuel => selectedFuel == fuel.id
        )
      )
    : [];

  const availableFuelTypes = VEHICLE_FUEL_TYPES?.filter(
    fuel => !selectedFuelTypes?.find(selectedFuel => selectedFuel.id === fuel.id)
  );

  const selectedCountries = selectedProcessor?.config?.rule?.country_codes
    ? selectedProcessor?.config?.rule?.country_codes?.map(code => ({
        id: code,
        name: code
      }))
    : [{ id: 'NL', name: 'NL' }];

  const availableCountries = countries?.filter(
    country => !selectedCountries?.find(selectedCountry => selectedCountry.id === country.id)
  );

  const formik = useFormik({
    initialValues: {
      id: selectedProcessor?.id,
      name: selectedProcessor?.name,
      makes_exempt_if_matches: selectedProcessor?.config?.makes_exempt === 'if_matches',
      scheduled_at_enabled: !!selectedProcessor?.config?.schedule_ical,
      schedule_ical: selectedProcessor?.config?.schedule_ical,
      enabledScanCars: !!selectedProcessor?.config?.rule?.camera_ids?.filter(cameraId =>
        useCase?.cameras?.find(
          camera => camera.id == cameraId && camera.type == 'Cop::MobileCamera'
        )
      ).length,
      enabledFixedCameras: !!selectedProcessor?.config?.rule?.camera_ids?.filter(cameraId =>
        useCase?.cameras?.find(camera => camera.id == cameraId && camera.type == 'Cop::FixedCamera')
      ).length,
      availableCameras,
      selectedFixedCameras,
      selectedMobileCameras,
      enabledVehicleTypes: !!selectedProcessor?.config?.rule?.vehicle_types?.length,
      selectedVehicleTypes,
      availableVehicleTypes,
      enabledDirections: !!selectedProcessor?.config?.rule?.vehicle_directions?.length,
      selectedDirections,
      availableDirections,
      enabledEmissionCodes: !!selectedProcessor?.config?.rule?.emission_codes?.length,
      availableEmissionCodes,
      selectedEmissionCodes,
      enabledEuropeanCategories: !!selectedProcessor?.config?.rule?.european_categories?.length,
      availableEuropeanCategories,
      selectedEuropeanCategories,
      enabledFuelTypes: !!selectedProcessor?.config?.rule?.types_of_fuel?.length,
      availableFuelTypes,
      selectedFuelTypes,
      enabledCountries: !!selectedProcessor?.config?.rule?.country_codes?.length,
      selectedCountries,
      availableCountries,
      is_taxi: !!selectedProcessor?.config?.rule?.is_taxi,
      missing_vehicle_info: !!selectedProcessor?.config?.rule?.missing_vehicle_info,
      enabledVehicleBuildYear: !!selectedProcessor?.config?.rule?.vehicle_build_year,
      vehicleBuildYear: true,
      vehicleBuildYearGt: selectedProcessor?.config?.rule?.vehicle_build_year?.gt ?? '',
      vehicleBuildYearLt: selectedProcessor?.config?.rule?.vehicle_build_year?.lt ?? '',
      enabledVehicleWidth: !!selectedProcessor?.config?.rule?.vehicle_width,
      vehicleWidth: true,
      vehicleWidthGt: selectedProcessor?.config?.rule?.vehicle_width?.gt ?? '',
      vehicleWidthLt: selectedProcessor?.config?.rule?.vehicle_width?.lt ?? '',
      enabledVehicleWeight: !!selectedProcessor?.config?.rule?.vehicle_max_weight,
      vehicleWeight: true,
      vehicleWeightGt: selectedProcessor?.config?.rule?.vehicle_max_weight?.gt ?? '',
      vehicleWeightLt: selectedProcessor?.config?.rule?.vehicle_max_weight?.lt ?? ''
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: values => {
      onSave(
        // eslint-disable-next-line no-undef
        _.pick(values, [
          'id',
          'name',
          'makes_exempt_if_matches',
          'missing_vehicle_info',
          'schedule_ical',
          'is_taxi',
          values['enabledFixedCameras'] ? 'selectedFixedCameras' : null,
          values['enabledDirections'] ? 'selectedDirections' : null,
          values['enabledCountries'] ? 'selectedCountries' : null,
          values['enabledScanCars'] ? 'selectedMobileCameras' : null,
          values['enabledVehicleTypes'] ? 'selectedVehicleTypes' : null,
          values['enabledEmissionCodes'] ? 'selectedEmissionCodes' : null,
          values['enabledEuropeanCategories'] ? 'selectedEuropeanCategories' : null,
          values['enabledFuelTypes'] ? 'selectedFuelTypes' : null,
          values['enabledVehicleBuildYear'] && values['vehicleBuildYearGt']
            ? 'vehicleBuildYearGt'
            : null,
          values['enabledVehicleBuildYear'] && values['vehicleBuildYearLt']
            ? 'vehicleBuildYearLt'
            : null,
          values['enabledVehicleWeight'] && values['vehicleWeightGt'] ? 'vehicleWeightGt' : null,
          values['enabledVehicleWeight'] && values['vehicleWeightLt'] ? 'vehicleWeightLt' : null,
          values['enabledVehicleWidth'] && values['vehicleWidthGt'] ? 'vehicleWidthGt' : null,
          values['enabledVehicleWidth'] && values['vehicleWidthLt'] ? 'vehicleWidthLt' : null
        ])
      );
    }
  });

  useImperativeHandle(outerRef, () => {
    return {
      handleSave: () => formik.submitForm(),
      handleCancel: () => formik.resetForm(),
      handleChange: formik.handleChange
    };
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.setErrors(formik.errors));
  }, [formik.errors]);

  const onSave = async values => {
    let rule = {};

    if (values.is_taxi) rule.is_taxi = values.is_taxi;
    if (values.missing_vehicle_info) rule.missing_vehicle_info = values.missing_vehicle_info;

    const fixedCams = values.selectedFixedCameras?.map(c => parseInt(c.id)) ?? [];
    const mobileCams = values.selectedMobileCameras?.map(c => parseInt(c.id)) ?? [];
    const cams = fixedCams.concat(mobileCams);
    if (cams.length > 0) rule.camera_ids = cams;

    if (values.selectedDirections)
      rule.vehicle_directions = values.selectedDirections.map(d => d.id);
    if (values.selectedCountries) rule.country_codes = values.selectedCountries.map(c => c.id);
    if (values.selectedVehicleTypes)
      rule.vehicle_types = values.selectedVehicleTypes.map(v => v.id);
    if (values.selectedEmissionCodes)
      rule.emission_codes = values.selectedEmissionCodes.map(e => e.id);
    if (values.selectedEuropeanCategories)
      rule.european_categories = values.selectedEuropeanCategories.map(e => e.id);
    if (values.selectedFuelTypes) rule.types_of_fuel = values.selectedFuelTypes.map(e => e.id);

    let vehicleBuildYear = {};
    if (values.vehicleBuildYearGt) vehicleBuildYear.gt = values.vehicleBuildYearGt;
    if (values.vehicleBuildYearLt) vehicleBuildYear.lt = values.vehicleBuildYearLt;
    // eslint-disable-next-line no-undef
    if (!_.isEmpty(vehicleBuildYear)) rule.vehicle_build_year = vehicleBuildYear;

    let vehicleWidth = {};
    if (values.vehicleWidthGt) vehicleWidth.gt = values.vehicleWidthGt;
    if (values.vehicleWidthLt) vehicleWidth.lt = values.vehicleWidthLt;
    // eslint-disable-next-line no-undef
    if (!_.isEmpty(vehicleWidth)) rule.vehicle_width = vehicleWidth;

    let vehicleWeight = {};
    if (values.vehicleWeightGt) vehicleWeight.gt = values.vehicleWeightGt;
    if (values.vehicleWeightLt) vehicleWeight.lt = values.vehicleWeightLt;
    // eslint-disable-next-line no-undef
    if (!_.isEmpty(vehicleWeight)) rule.vehicle_max_weight = vehicleWeight;

    const params = {
      id: values.id,
      name: values.name,
      config: {
        makes_exempt: values.makes_exempt_if_matches ? 'if_matches' : 'unless_matches'
      }
    };

    // eslint-disable-next-line no-undef
    if (!_.isEmpty(rule)) params.config.rule = rule;
    if (values.schedule_ical) params.config.schedule_ical = values.schedule_ical;

    await persistPipelineProcessor({ variables: params });
  };

  const shouldShowVehicleInfoField = () => {
    return !formik.values.missing_vehicle_info;
  };

  useEffect(() => {
    if (formik.values.missing_vehicle_info) {
      VEHICLE_INFO_RELATED_FIELDS.forEach(field => {
        formik.setFieldValue(field, false);
      });
    }
  }, [formik.values.missing_vehicle_info]);

  return !vehicleTypesLoading ? (
    <StyledExemptionRuleChecker>
      <div className="content">
        <FormFieldBlock
          field={
            <>
              <div className="alert alert-success" role="alert">
                {I18n.t('settings.pipelines.processors.messages.on_error_exempt')}
              </div>
            </>
          }
        />
        <MakesExemptField formik={formik} editEnabled={editEnabled} />

        <FormFieldBlock
          label={I18n.t('settings.pipelines.processors.form.offense_decorator.criteria')}
          className="criteria"
          error={formik.errors.criteria}
        />

        <FormFieldSmall
          label={I18n.t(
            'settings.pipelines.processors.exemption_rule_checker.missing_vehicle_info_label'
          )}
          field={
            <LabelToggle
              id="missing_vehicle_info"
              name="missing_vehicle_info"
              isOn={formik.values.missing_vehicle_info ?? false}
              onChange={formik.handleChange}
              disabled={!editEnabled}
            />
          }
          error={formik.errors.missing_vehicle_info}
        />

        <Scheduler formik={formik} editEnabled={editEnabled} />

        {formik.errors.schedule_ical && <p className="error">{formik.errors.schedule_ical}</p>}

        <FixedCameraField
          formik={formik}
          switchLabel={I18n.t('settings.use_case_fixed_cameras_label')}
          editEnabled={editEnabled}
          isSwitchable={true}
        />
        <ScanCarField
          formik={formik}
          switchLabel={I18n.t('settings.use_case_mobile_cameras_label')}
          editEnabled={editEnabled}
          isSwitchable={true}
        />
        <DirectionField
          formik={formik}
          switchLabel={I18n.t('settings.pipelines.processors.form.offense_decorator.directions')}
          editEnabled={editEnabled}
          isSwitchable={true}
        />
        <CountryField
          formik={formik}
          switchLabel={I18n.t('settings.pipelines.processors.form.offense_decorator.countries')}
          editEnabled={editEnabled}
          isSwitchable={true}
        />
        {shouldShowVehicleInfoField() && (
          <>
            <VehicleTypeField
              formik={formik}
              switchLabel={I18n.t('root.base_data.vehicle_types.label.plural')}
              editEnabled={editEnabled}
              isSwitchable={true}
            />
            <EmissionCodeField
              formik={formik}
              switchLabel={I18n.t(
                'settings.pipelines.processors.form.offense_decorator.emission_codes'
              )}
              editEnabled={editEnabled}
              isSwitchable={true}
            />
            <EuropeanCategoryField
              formik={formik}
              switchLabel={I18n.t(
                'settings.pipelines.processors.form.offense_decorator.european_categories'
              )}
              editEnabled={editEnabled}
              isSwitchable={true}
            />
            <FuelTypeField
              formik={formik}
              switchLabel={I18n.t(
                'settings.pipelines.processors.form.offense_decorator.types_of_fuel'
              )}
              editEnabled={editEnabled}
              isSwitchable={true}
            />
            <FormFieldSmall
              label={I18n.t('settings.pipelines.processors.form.offense_decorator.is_taxi')}
              field={
                <LabelToggle
                  id="is_taxi"
                  name="is_taxi"
                  isOn={formik.values.is_taxi ?? false}
                  onChange={formik.handleChange}
                  disabled={!editEnabled}
                />
              }
              error={formik.errors.is_taxi}
            />
            <VehicleBuildYearField
              formik={formik}
              switchLabel={I18n.t(
                'settings.pipelines.processors.form.offense_decorator.vehicle_build_year'
              )}
              editEnabled={editEnabled}
              isSwitchable={true}
            />

            <VehicleWidthField
              formik={formik}
              switchLabel={I18n.t(
                'settings.pipelines.processors.form.offense_decorator.vehicle_width'
              )}
              editEnabled={editEnabled}
              isSwitchable={true}
            />

            <VehicleWeightField
              formik={formik}
              switchLabel={I18n.t(
                'settings.pipelines.processors.form.offense_decorator.vehicle_weight'
              )}
              editEnabled={editEnabled}
              isSwitchable={true}
            />
          </>
        )}
      </div>
    </StyledExemptionRuleChecker>
  ) : (
    <Loading />
  );
}

export default ExemptionRuleChecker;
